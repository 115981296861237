<script setup lang="ts">
type NavigationLinkProps = {
  title: string;
  icon: Array<string>;
  closeSidebar?: boolean;
  pill?: string;
  to?: string | undefined;
  href?: string | undefined;
  badgeCount?: number | undefined;
};

type NavigationLinkEmits = {
  (e: 'closeSidebar'): void;
};

const props = withDefaults(defineProps<NavigationLinkProps>(), {
  to: undefined,
  href: undefined,
  closeSidebar: true,
  pill: '',
  badgeCount: undefined,
});

defineEmits<NavigationLinkEmits>();

const countLabel = computed(() => {
  if (!props.badgeCount || props.badgeCount === 0) return;

  return props.badgeCount > 99 ? '99+' : props.badgeCount.toString();
});
</script>

<template>
  <NuxtLink
    :to="to"
    :href="href"
    class="group flex items-center rounded-lg px-4 py-2 text-base font-normal text-gray-900 hover:bg-gray-50 dark:text-white dark:hover:bg-gray-700"
    active-class="nav-left-link--active"
    @click="$emit('closeSidebar')"
  >
    <div class="relative">
      <FontAwesomeIcon
        :icon="icon"
        size="lg"
        class="icon h-[24px] w-[24px] text-gray-400 transition-colors group-hover:text-gray-400 group-[.router-link-exact-active]:text-gray-400 dark:group-hover:text-white dark:group-[.router-link-exact-active]:text-white"
      />
    </div>
    <span class="ml-3" :class="{ 'ml-3 flex-1 whitespace-nowrap': pill }">{{
      title
    }}</span>
    <span
      v-if="badgeCount"
      class="min-h-5 ml-auto inline-flex min-w-5 items-center justify-center rounded-full bg-red-100 px-2 py-0.5 text-xs font-medium text-red-700 dark:bg-blue-200 dark:text-blue-800"
    >
      {{ countLabel }}
    </span>
  </NuxtLink>
</template>

<style scoped lang="scss">
.nav-left-link--active {
  @apply font-semibold;

  @media (prefers-color-scheme: dark) {
    @apply bg-blue-500/20;
  }

  .icon {
    @apply text-gray-900;
  }
}
</style>
