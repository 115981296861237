<script setup lang="ts">
import { Drawer } from 'flowbite';
import { onMounted, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { PROPERTY_STACK, TEST_ESTATES } from '@constants';
import CloseButton from '~/components/navigation/appSidebar/CloseButton.vue';
import LivePropertyIndicator from '~/components/navigation/appSidebar/LivePropertiesIndicator.vue';
import NavigationLink from '~/components/navigation/appSidebar/NavigationLink.vue';
import { useMainStore } from '~/stores/mainStore';

const mainStore = useMainStore();

const { drawer: show, toggleDrawer } = useLayout();

const { liveUnreadEnquiriesCount } = storeToRefs(useMainStore());

const betaSidebarBannerDismissed = useCookie('beta_sidebar_banner_dismissed', {
  default: () => false,
  maxAge: 60 * 60 * 24 * 365,
  watch: true,
});

const showTenancies = computed(() => {
  const user = mainStore.auth?.user;

  if (!user) {
    return false;
  }

  return (
    user.tenancies_enabled && [PROPERTY_STACK, TEST_ESTATES].includes(user.id)
  );
});

const drawerEl = ref<HTMLElement | null>(null);
const drawer = ref<Drawer | null>(null);

onMounted(() => {
  if (drawerEl.value) {
    drawer.value = new Drawer(drawerEl.value, {
      onHide: () => {
        show.value = false;
      },
      onShow: () => {
        show.value = true;
      },
    });
  }
});

const dismissBetaSidebarBanner = () => {
  // Dismiss the banner and save that for a year
  betaSidebarBannerDismissed.value = true;
};

watch(show, () => {
  if (show.value) {
    drawer.value?.show();
  } else {
    drawer.value?.hide();
  }
});

const tenanciesUrl = computed(() => useZiggy('landlord.tenancy.index'));

const agentPortalVersionSwitchUrl = computed(() =>
  useZiggy('landlord.version', '1.0'),
);
</script>

<template>
  <aside
    id="sidebar-menu"
    ref="drawerEl"
    class="fixed left-0 top-0 z-40 h-screen min-w-56 max-w-2xl -translate-x-full flex-col transition-transform xl:relative xl:block xl:translate-x-0 xxl:min-w-[260px]"
    aria-label="Sidebar"
    data-cy="navigation-left"
  >
    <div
      class="relative h-full overflow-y-auto border-r bg-white pb-20 pt-8 dark:border-gray-700 dark:bg-gray-800 xl:pt-4"
    >
      <section class="px-3 py-4">
        <CloseButton @click="toggleDrawer" />
        <LivePropertyIndicator class="mb-0" />
      </section>

      <section class="px-3 pt-6">
        <ul class="space-y-2">
          <li>
            <NavigationLink
              title="Dashboard"
              to="/"
              :icon="['far', 'gauge-max']"
            />
          </li>
          <li>
            <NavigationLink
              title="Properties"
              to="/properties"
              :icon="['far', 'house']"
            />
          </li>
          <li>
            <NavigationLink
              title="Enquiries"
              to="/enquiries"
              :icon="['far', 'envelope']"
              :badge-count="liveUnreadEnquiriesCount"
            />
          </li>
          <li>
            <NavigationLink
              title="Utilities"
              to="/utilities"
              :icon="['far', 'bolt']"
            />
          </li>
          <li>
            <NavigationLink
              title="Analytics"
              to="/analytics"
              :icon="['far', 'chart-user']"
            />
          </li>
          <li v-if="showTenancies">
            <NavigationLink
              title="Tenancies"
              :href="tenanciesUrl"
              :icon="['far', 'file-contract']"
            />
          </li>
          <li>
            <NavigationLink
              title="Resources"
              href="https://unihomes.helpdocs.io/"
              :icon="['far', 'book']"
              target="_blank"
              rel="noopener noreferrer"
            />
          </li>
        </ul>
      </section>

      <section
        v-if="
          mainStore.siteSettings.showAgentPortalBetaToggleSwitch &&
          !betaSidebarBannerDismissed
        "
        class="px-3 py-6 xl:absolute xl:bottom-12"
      >
        <div class="rounded-lg border-2 border-blue-500 bg-blue-50 p-4 text-sm">
          <div class="mb-3 flex items-center">
            <span
              class="ml-0 mr-auto inline-block rounded-full bg-blue-700 px-2.5 py-0.5 text-sm text-xs font-medium text-white"
              >Beta</span
            >
            <FontAwesomeIcon
              class="cursor-pointer text-base"
              :icon="['fas', 'times']"
              @click="dismissBetaSidebarBanner"
            />
          </div>
          <p class="font-semibold leading-snug">New look, same great tools!</p>
          <p class="mb-4 text-sm leading-snug">
            You’re currently using the new beta version of the Agent Portal.
          </p>
          <p class="mb-4 text-sm leading-snug">
            We’ve worked hard to ensure everything works as you need it to, but
            if you do encounter issues, feel free to switch back to the legacy
            portal.
          </p>
          <p>
            <a
              :href="agentPortalVersionSwitchUrl"
              class="font-semibold underline"
            >
              Switch back
            </a>
          </p>
        </div>
      </section>
    </div>
  </aside>
</template>
